window.$ = window.jQuery = require('jquery');

$(document).ready(function () {
    $('.link').click(function () {
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });
});



